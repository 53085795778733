import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { MdOndemandVideo } from "react-icons/md"

import { SectionTitle, VideoList, VideoSection } from "./VideoElements"
import VideoCard from "./VideoCard"

const getVideos = graphql`
  query {
    videos: allContentfulVideo(sort: { order: DESC, fields: date }, limit: 3) {
      nodes {
        id: contentful_id
        title
        slug
        videoUrl
        date
        formatDate: date(formatString: "MMM D, YYYY")
        excerpt
      }
    }
  }
`

const Videos = () => {
  const data = useStaticQuery(getVideos)
  const allVideos = data.videos.nodes

  return (
    <VideoSection>
      <SectionTitle>
        <h2>
          <MdOndemandVideo /> <span>/</span> Videos
        </h2>
      </SectionTitle>

      <VideoList>
        {allVideos.map(node => {
          return (
            <VideoCard
              key={node.id}
              url={node.videoUrl}
              title={node.title}
              date={node.date}
              vidDate={node.formatDate}
              excerpt={node.excerpt}
            />
          )
        })}
      </VideoList>
    </VideoSection>
  )
}

export default Videos
