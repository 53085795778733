import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Card, CardContent, CardLink, CardMeta } from "./ImageCardElements"

const ImageCard = ({ story, fullSlug }) => {
  const {
    title,
    excerpt,
    thumbnail,
    date,
    validDate,
    category,
    subCategory,
  } = story
  const imageData = getImage(thumbnail)

  return (
    <Card>
      <CardLink to={fullSlug}>

        <GatsbyImage
          className="articleImage"
          imgStyle={{ transition: `all .5s ease-out` }}
          image={imageData}
            alt={title}
        />
        <CardContent>
          <CardMeta>
            <time dateTime={validDate}>{date}</time>
            <span>{subCategory?.title ?? category?.title}</span>
          </CardMeta>
          <h3>{title}</h3>
          <p className="line-clamp">
            {excerpt}
          </p>
        </CardContent>
      </CardLink>
    </Card>
  )
}

export default ImageCard
