import styled from "@emotion/styled"

// Scroller
export const ScrollerWrapper = styled.figure`
  --transition: all 0.3s ease-in-out;
  background-color: var(--grey-1);
  grid-column: center-start / col-end 4;
  margin-right: 4rem;
  min-width: 28rem;
  padding: 0 4rem;
  transition: var(--transition);

  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid var(--grey-3);

  @media (max-width: 35em) {
    font-size: 2.6rem;
    padding: 0;
  }

  figcaption {
    grid-column: 2 / 3;
    grid-row: 3 / -1;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;

    a {
      background-color: rgb(247 214 40 / 0.5);
      border: 1px solid transparent;
      border-radius: 0.5rem;
      color: rgba(0, 0, 0, 0.55);
      font-size: 1.75rem;
      font-weight: 900;
      margin: 3.5rem 1rem 0;
      padding: 1.5rem;
    }

    a:hover,
    a:focus {
      background-color: rgba(255, 255, 255, 0.92);
      border: 1px solid rgb(0 0 0 / 0.25);
      color: rgb(0 0 0 / 0.55);
    }
  }

  .paginateBtn {
    background-color: var(--white);
    border: none;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    color: var(--grey-5);
    cursor: pointer;
    font-size: 2rem;
    grid-row: 2 / 3;
    height: 4rem;
    margin: 0 auto;
    transition: var(--transition);
    width: 4rem;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: var(--grey-2);
      color: var(--grey-5);
    }
  }

  .prevBtn {
    grid-column: 1 / 2;
    flex: 1;
    transform: translateX(-1.5rem);
  }
  .nextBtn {
    grid-column: 3 / 4;
    flex: 1;
    transform: translateX(1rem);
  }

  @media (max-width: 52.5em) {
    grid-column: center-start / center-end;
    margin: 0;
  }
`

export const QuoteTextWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  /* gap: 2rem; */
`

export const QuoteBlock = styled.blockquote`
  background-color: var(--grey-1);
  border-radius: 1rem;
  grid-column: 2 / 3;
  grid-row: 2/3;
  padding-top: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;

  cite {
    color: var(--grey-7);
    font-size: 1.8rem;
  }
`

export const QuoteText = styled.p`
  color: var(--grey-7);
  font-size: 2.9rem;
  font-style: italic;
  font-weight: 900;
  letter-spacing: -0.005em;
  line-height: 1.4em;
  margin: 8rem 0 4rem;
  position: relative;

  &::before {
    color: var(--primaryYellow);
    content: "“";
    font-family: sans-serif;
    font-size: 20rem;
    /* left: -1.3rem; */
    left: -6.4rem;
    /* line-height: 1; */
    opacity: 0.7;
    position: absolute;
    top: -5.7rem;
  }
`
