import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { ArticleContent, ExecCard, ExecCardLink } from "./ExecutiveElements"

const ExecutiveCard = ({ article }) => {
  const { title, slug, excerpt, thumbnail, category, subCategory } = article
  const imageData = getImage(thumbnail)

  return (
    <ExecCard>
      <ExecCardLink to={`/${category.slug}/${subCategory.slug}/${slug}/`}>
        <ArticleContent>
          <h3>{title}</h3>
          <p className="excerpt">{excerpt}</p>
        </ArticleContent>

        <GatsbyImage className="articleImage" image={imageData} alt={title} />
      </ExecCardLink>
    </ExecCard>
  )
}
export default ExecutiveCard
