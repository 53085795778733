import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

import { ScrollerWrapper } from "./ScrollerElements"
import QuoteScroller from "../Quote/QuoteScroller"

const quoteQuery = graphql`
  query {
    quotes: allContentfulQuote(limit: 5, sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        author {
          name
        }
        subCategory {
          title
        }
        body {
          body
        }
      }
    }
  }
`

const Scroller = ({ children }) => {
  const data = useStaticQuery(quoteQuery)
  const quotes = data.quotes.nodes

  return (
    <ScrollerWrapper>
      {children}
      <QuoteScroller quotes={quotes} />
      <figcaption>
        <Link to="/inspirational-quotes/">More Quotes</Link>
        {/* <Link to="/inspirational-quotes/life-quotes/">Business Quotes</Link> */}
      </figcaption>
    </ScrollerWrapper>
  )
}

export default Scroller
