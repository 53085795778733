import styled from "@emotion/styled"
import { Link } from "gatsby"

export const EditorialWrapper = styled.section`
  grid-column: col-start 5 / center-end;

  @media (max-width: 52.5em) {
    grid-column: center-start / center-end;
  }
`

export const LatestEd = styled.div`
  border-bottom: 1px solid var(--grey-4);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(28rem, 1fr));
  grid-gap: 4rem;
  justify-items: start;

  .gatsby-image-wrapper {
    border-radius: 5px;
    height: 75%;
    order: 2;
  }
`

export const EditorialContent = styled.div`
  & > * {
    display: block;
    margin-bottom: 4rem;
  }
`

export const EditorialList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  padding-top: 4rem;
  grid-gap: 1rem;
`

export const EditorialItem = styled.li`
  background-color: hsla(216.2, 98%, 61.2%, 0.05);
  border-radius: 5px;
  color: var(--black);
  display: grid;
  align-content: start;
  justify-content: center;
  flex: calc(100% / 3);
  padding: 1.5rem;
  text-align: center;

  time {
    color: var(--grey-7);
    font-size: 1.4rem;
    padding-bottom: 2rem;
  }
`

export const EdBtnLink = styled(Link)`
  border-radius: 10rem;
  padding: 0.7rem 3.5rem;
  border: 1px solid var(--grey-7);
  text-align: center;
  font-size: 1.4rem;
  display: inline-block;
`
