import React from "react"
import { VideoWrapper, VideoContent } from "./VideoElements"
import ReactPlayer from "react-player"

const VideoCard = ({ url, date, vidDate, title, excerpt }) => {
  return (
    <figure>
      <VideoWrapper>
        <ReactPlayer
          className="reactPlayer"
          url={url}
          width="100%"
          height="100%"
          pip={true}
          controls={true}
        />
      </VideoWrapper>

      <VideoContent>
        <time dateTime={date}>{vidDate}</time>
        {/* <time>{vidDate}</time> */}
        <h3>{title}</h3>
        <p>{excerpt}</p>
      </VideoContent>
    </figure>
  )
}

export default VideoCard
