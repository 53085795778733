import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/seo"
import { Main } from "../styles/Layout"
import { StyledHeadingBar } from "../styles/headings"

import BusinessExecutive from "../components/Executive"
// import Ad from "../components/Ads/index"
import Brands from "../components/Brands"
import Review from "../components/Review"
import RecentStory from "../components/RecentStory"
import Videos from "../components/Videos"
import Scroller from "../components/Scroller/index"
import Editorial from "../components/Editorial"
import AdLeaderboard from "../components/Ads/AdLeaderboard"
import Newsletter from "../components/Newsletter/index"

const HomePage = ({ data }) => {
  const [recent, ...restExecs] = data.bizExecs.nodes
  const { title, slug, date, validDate, category, subCategory, featuredImage } =
    recent
  const adData = data.adLeaderboard
  const [brandsData] = data.brands.nodes
  const { nodes } = data.reviews
  const storyNodes = data.stories.nodes

  return (
    <>
      <Seo
        description="Get insights, guides, and advice on how to start a business and succeed"
        imageAlt="CEIimages.com banner"
      />
      <Main
        id="main-content"
        // @ts-ignore
        // vertSpace
      >
        <BusinessExecutive
          title={title}
          slug={`/${category.slug}/${subCategory.slug}/${slug}/`}
          pubDate={date}
          validDate={validDate}
          subCategory={subCategory.title}
          image={featuredImage}
          articles={restExecs}
        />
        <AdLeaderboard {...adData} homepage />
        <Brands {...brandsData} />
        <Review reviews={nodes} />
        <RecentStory storyNodes={storyNodes} />
        <Videos />
        <Scroller>
          <StyledHeadingBar style={{ gridColumn: "2 / 5" }}>
            Inspirational Quotes <span>Life + Business</span>
          </StyledHeadingBar>
        </Scroller>
        <Editorial />
        <Newsletter
          formHeading="our Newsletter"
          formWidth={null}
          className={null}
        />
      </Main>
    </>
  )
}

export default HomePage

export const homePageQuery = graphql`
  query {
    bizExecs: allContentfulArticle(
      filter: { subCategory: { title: { eq: "Business Executives" } } }
      sort: { fields: publishDate, order: DESC }
      limit: 5
    ) {
      nodes {
        id: contentful_id
        title
        slug
        date: publishDate(formatString: "MM.DD.YYYY")
        validDate: publishDate(formatString: "YYYY-MM-DD")
        excerpt
        featuredImage {
          description
          gatsbyImageData(
            width: 1200
            height: 620
            placeholder: TRACED_SVG
            layout: FULL_WIDTH
            quality: 65
            formats: [AUTO, WEBP]
          )
        }
        thumbnail: featuredImage {
          description
          gatsbyImageData(
            width: 120
            placeholder: TRACED_SVG
            layout: CONSTRAINED
            quality: 40
            formats: [AUTO, WEBP]
          )
        }
        category {
          title
          slug
        }
        subCategory {
          slug
          title
        }
      }
    }

    adLeaderboard: contentfulAds(
      adType: { eq: "Leaderboard" }
      adActive: { eq: true }
      homepageAd: { eq: true }
    ) {
      title
      adUrl
      adType
      adGraphic {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: TRACED_SVG
          quality: 100
          formats: [AUTO, WEBP, PNG]
        )
      }
    }

    brands: allContentfulArticle(
      filter: { subCategory: { title: { eq: "Brands of the world" } } }
      sort: { fields: publishDate, order: DESC }
    ) {
      nodes {
        title
        slug
        excerpt
        featuredImage {
          description
          gatsbyImageData(
            width: 600
            placeholder: BLURRED
            quality: 40
            formats: [AUTO, WEBP]
          )
        }
        subCategory {
          slug
        }
        category {
          slug
        }
      }
    }

    reviews: allContentfulReview(
      limit: 3
      sort: { fields: publishDate, order: DESC }
    ) {
      nodes {
        id: contentful_id
        slug
        title
        pubDate: publishDate(formatString: "DD.MM.YYYY")
        validDate: publishDate(formatString: "YYYY-MM-DD")
        summary
        featuredImage {
          description
          gatsbyImageData(
            width: 300
            placeholder: TRACED_SVG
            quality: 40
            formats: [AUTO, WEBP]
            layout: FULL_WIDTH
          )
          reviewThumb: gatsbyImageData(
            width: 100
            placeholder: BLURRED
            quality: 30
            formats: [AUTO, WEBP]
          )
        }
        price
        functionality
        style
        overallRating
        category {
          slug
        }
        subCategory {
          title
          slug
        }
      }
    }

    stories: allContentfulArticle(
      filter: {
        subCategory: {
          title: {
            nin: [
              "Business Executives"
              "Product Reviews"
              "Brands of the world"
              "Editorial"
            ]
          }
        }
      }
      limit: 12
      sort: { fields: publishDate, order: DESC }
    ) {
      nodes {
        id: contentful_id
        title
        slug
        date: publishDate(formatString: "L")
        validDate: publishDate(formatString: "YYYY-MM-DD")
        category {
          title
          slug
        }
        subCategory {
          title
          slug
        }
        excerpt
        thumbnail: featuredImage {
          gatsbyImageData(
            width: 120
            placeholder: BLURRED
            layout: FULL_WIDTH
            quality: 40
            formats: [AUTO, WEBP]
            aspectRatio: 1.77777778
          )
        }
      }
    }
  }
`
