import styled from "@emotion/styled"

export const VideoSection = styled.section`
  grid-column: full-start / full-end;
`

export const SectionTitle = styled.header`
  grid-column: 1 / -1;
  grid-row: 1 / 2;
  justify-self: stretch;
  padding-bottom: 6rem;
  text-align: center;
  text-transform: uppercase;
  opacity: 0.75;

  h2 {
    display: flex;
    justify-content: center;
  }

  span {
    color: var(--grey-7);
    margin: 0 0.5rem;
  }
`

export const VideoList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
  grid-template-rows: auto 1fr;
  justify-content: space-between;
  gap: 4rem;

  @media (max-width: 42.5em) {
    /* grid-template-columns: repeat(auto-fit, minmax(32rem, 1fr)); */
    grid-template-columns: 1fr;
  }
`

export const VideoWrapper = styled.div`
  overflow: hidden;
  padding-bottom: 56.2%;
  position: relative;
  margin: 3rem auto;
  margin: 0 auto;

  .reactPlayer {
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`

export const VideoContent = styled.figcaption`
  padding: 2rem 4rem 0 2rem;

  @media (max-width: 42.5) {
    padding: 2rem 4rem 0;
  }

  time {
    color: var(--grey-7);
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
  }

  h3 {
    margin-bottom: 1.5rem;
  }
`
