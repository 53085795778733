import React from "react"
import { FaArrowRight as ArrowRight } from "react-icons/fa"

import { StyledHeadingBar } from "../../styles/headings"
import { CardContent } from "../../utils/cards/ImageCard/ImageCardElements"
import ImgBgCard from "../ui/ImgBgCard"
import {
  MoreLinkWrapper,
  MoreLink,
  ReviewContent,
  ReviewHeader,
  ReviewWrapper,
  ReviewList,
} from "./ReviewElements"

const Review = ({ reviews }) => {
  const [latestReview, ...otherReviews] = reviews
  // const otherReviews = [reviewOne, reviewTwo]
  const {
    title,
    slug,
    pubDate,
    validDate,
    featuredImage,
    overallRating,
    summary,
    category,
    subCategory,
  } = latestReview

  return (
    <ReviewWrapper>
      <ReviewHeader>
        <StyledHeadingBar>
          <span>Editor's Choice</span>
          Product, Service & Book Reviews
        </StyledHeadingBar>
        <MoreLinkWrapper>
          <span>Read more</span>{" "}
          <MoreLink to="/reviews/">
            <ArrowRight />
          </MoreLink>
        </MoreLinkWrapper>
      </ReviewHeader>

      <ReviewContent>
        <ImgBgCard
          center={false}
          title={
            (featuredImage?.description && featuredImage.description) || title
          }
          image={featuredImage}
          url={`/${category?.slug}/${subCategory?.slug}/${slug}/`}
        >
          <CardContent>
            <div className="card-date">
              <time dateTime={validDate}>{pubDate}</time> |{" "}
              <span aria-label={`Rating of ${overallRating} out of 5 stars`}>
                {`⭐`.repeat(overallRating)}
                <span style={{ filter: `grayscale(100%)` }}>
                  {`⭐`.repeat(5 - Number(overallRating))}
                </span>
              </span>
            </div>
            <h3>{title}</h3>
            <p className="line-clamp">{summary}</p>
          </CardContent>
        </ImgBgCard>

        <ReviewList>
          {otherReviews.map(item => {
            return (
              <ImgBgCard
                center="center"
                key={item.id}
                title={
                  (item?.featuredImage?.description &&
                    item.featuredImage.description) ||
                  item.title
                }
                image={item.featuredImage}
                url={`/${item.category?.slug}/${item.subCategory?.slug}/${item.slug}/`}
              >
                <CardContent>
                  <h4>{item.title}</h4>
                </CardContent>
              </ImgBgCard>
            )
          })}
        </ReviewList>
      </ReviewContent>
    </ReviewWrapper>
  )
}

export default Review
