import styled from "@emotion/styled"
import { Link } from "gatsby"

export const Card = styled.article`
  background-color: rgba(240, 237, 237, 0.73);
  border-radius: 6px;
  color: initial;
  margin-top: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 3px 3px 0.6rem rgba(0, 0, 0, 0.08);
  }

  &:hover picture img {
    transform: scale(1.1);
  }
`

export const CardLink = styled(Link)`
  a:link {
    color: currentColor;
  }
`

export const CardContent = styled.div`
  padding: 2rem;

  h3 {
    font-size: 1.553rem;
    margin-bottom: 1rem;
  }

  .image {
    background-color: #fff;
    height: 25rem;
    overflow: hidden;
    display: block;
  }

  .card-date {
    opacity: 0.75;
    font-size: 1.3rem;
  }
`

export const CardMeta = styled.p`
  color: rgba(0, 0, 0, 0.43);
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: items;
`
