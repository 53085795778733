import styled from "@emotion/styled"

export const BrandsWrapper = styled.div`
  --spacing: 9%;
  grid-column: center-start / col-end 4;
  margin: 0 4rem 0 0;
  color: var(--white);
  min-width: 28rem;

  display: grid;
  grid-template-columns: repeat(3, 1fr) var(--spacing);
  grid-template-rows: var(--spacing) max-content 1fr max-content var(--spacing);

  &::after {
    content: "";
    grid-row: 1/ -1;
    grid-column: 2 / -1;
    background-color: hsla(205.7, 81.4%, 83.1%, 0.3);
    background-color: var(--grey-4);
    z-index: -1;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 45% 100%);
  }
  .brand-image {
    background-color: gray;
    grid-row: 2 / 5;
    grid-column: 1 / 4;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.35) 2%,
        rgba(0, 0, 0, 0.55) 20%,
        transparent,
        rgba(0, 0, 0, 0.75) 95%,
        rgba(0, 0, 0, 1) 100%
      );
    }
  }

  @media (max-width: 52.5em) {
    grid-column: center-start / center-end;
    margin: 0;
  }
`
export const BrandTitle = styled.h2`
  color: var(--white);
  display: inline-block;
  grid-row: 2 / 3;
  grid-column: 1 / 4;
  z-index: 5;
  text-transform: uppercase;
  padding-top: 2.5rem;
  padding-left: calc(var(--spacing) - 0.1rem);
  margin-top: 2.5rem;
  position: relative;
  opacity: 0.85;

  &::before {
    opacity: 0.65;
    content: "";
    background-color: currentColor;
    position: relative;
    height: 4px;
    width: 21%;
    display: flex;
    margin-bottom: 2rem;
    z-index: 5;
  }
`
export const BrandContent = styled.div`
  /* background-color: var(--grey-5); */
  grid-row: 4 / 5;
  grid-column: 1 / 4;
  padding: 2rem var(--spacing) 2rem 8%;
  z-index: 5;

  h3 {
    padding-bottom: 2rem;
    border-bottom: 1px solid var(--grey-4);
    margin-bottom: 1rem;
  }

  @media (max-width: 52.5em) {
    /* margin: 8rem 0 4rem 0; */
  }
`
