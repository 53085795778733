import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {
  EdBtnLink,
  EditorialContent,
  EditorialList,
  EditorialWrapper,
  LatestEd,
} from "./EditorialElements"
import { StyledHeadingBar, StyledHeadingSquare } from "../../styles/headings"
import EditorialCard from "./EditorialCard"

const getEditorials = graphql`
  query {
    editorials: allContentfulArticle(
      filter: { category: { title: { regex: "/opinion/i" } } }
      limit: 4
      sort: { fields: publishDate, order: DESC }
    ) {
      nodes {
        id: contentful_id
        slug
        title
        date: publishDate(formatString: "MM.DD.YYYY")
        excerpt
        featuredImage {
          description
          gatsbyImageData(
            width: 400
            aspectRatio: 1
            placeholder: TRACED_SVG
            layout: CONSTRAINED
            quality: 65
            formats: [AUTO, WEBP]
          )
        }
        category {
          slug
        }
        subCategory {
          title
          slug
        }
      }
    }
  }
`

const Editorial = () => {
  const data = useStaticQuery(getEditorials)
  const [latestEditorial, ...otherEditorials] = data.editorials.nodes
  const imageData = getImage(latestEditorial.featuredImage)

  return (
    <EditorialWrapper>
      <StyledHeadingSquare color="var(--primaryBlue)">
        Opinions
      </StyledHeadingSquare>
      <LatestEd>
        <GatsbyImage
          image={imageData}
          alt={
            (latestEditorial.featuredImage?.description &&
              latestEditorial.featuredImage?.description) ||
            latestEditorial.title
          }
        />

        <EditorialContent>
          <StyledHeadingBar color="var(--primaryBlue)">
            <span>Latest</span> {latestEditorial.subCategory.title}
          </StyledHeadingBar>

          <h3>{latestEditorial.title}</h3>
          {/* <time dateTime={new Date(latestEditorial.date).toISOString()}> */}
          <time>{latestEditorial.date}</time>
          <p>{latestEditorial.excerpt}</p>

          <EdBtnLink
            to={`/${latestEditorial.category.slug}/${latestEditorial.subCategory.slug}/${latestEditorial.slug}/`}
            aria-label={`Read more about ${latestEditorial.title}`}
          >
            Read more
          </EdBtnLink>
        </EditorialContent>
      </LatestEd>

      <EditorialList>
        {otherEditorials.map(node => {
          return <EditorialCard key={node.id} node={node} />
        })}
      </EditorialList>
    </EditorialWrapper>
  )
}

export default Editorial
