import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { BrandsWrapper, BrandTitle, BrandContent } from "./BrandsElements"

const Brands = ({
  title,
  slug,
  excerpt,
  featuredImage,
  description,
  subCategory,
  category,
}) => {
  const imageData = getImage(featuredImage)
  return (
    <BrandsWrapper>
      <BrandTitle>Brands of the World</BrandTitle>
      <GatsbyImage
        className="brand-image"
        image={imageData}
        alt={(description && description) || title}
      />
      <BrandContent>
        <Link to={`/${category.slug}/${subCategory.slug}/${slug}/`}>
          <h3>{title}</h3>
          {/* <p>{excerpt}</p> */}
        </Link>
      </BrandContent>
    </BrandsWrapper>
  )
}

export default Brands
