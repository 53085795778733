import styled from "@emotion/styled"

export const StyledHeadingSquare = styled.h2`
  color: inherit;
  line-height: 1.154;
  margin-bottom: 4rem;

  &::after {
    background-color: ${props => props.color || `currentColor`};
    content: "";
    display: inline-block;
    height: 0.7rem;
    width: 0.7rem;
    margin-left: 0.3rem;
    opacity: 0.85;
  }
`

export const StyledHeadingBar = styled.h3`
  color: inherit;
  display: inline-block;
  line-height: 1.154;
  margin-bottom: 4rem;
  padding-top: 4rem;
  position: relative;
  /* font-weight: 700; */

  span {
    font-weight: 400;
    font-variation-settings: "wght" 400, "ital" 1;
    text-transform: uppercase;
    display: block;
    color: var(--grey-7);
  }

  &::after {
    background-color: ${props => props.color || `currentColor`};
    /* background:
      linear-gradient(
        135deg, var(--primaryBlue) 0%,
        var(--primaryYellow) 50%,
        var(--primaryRed) 100%
      ); */
    content: "";
    height: 4px;
    left: 0;
    margin-left: 0.3rem;
    margin-top: 4rem;
    opacity: 0.85;
    position: absolute;
    width: 5.5rem;
    top: -4rem;
  }
`
