import React, { useState } from "react"
import { FaChevronRight, FaChevronLeft } from "react-icons/fa"

import {
  QuoteBlock,
  QuoteText,
  QuoteTextWrapper,
} from "../Scroller/ScrollerElements"

const QuoteScroller = ({ quotes }) => {
  const [index, setIndex] = useState(0)
  const { title, author, subCategory, body } = quotes[index]

  const checkNumber = number => {
    if (number > quotes.length - 1) {
      return 0
    }
    if (number < 0) {
      return quotes.length - 1
    }
    return number
  }

  const prevQuote = () => {
    setIndex(index => {
      let newIndex = index - 1
      return checkNumber(newIndex)
    })
  }
  const nextQuote = () => {
    setIndex(index => {
      let newIndex = index + 1
      return checkNumber(newIndex)
    })
  }

  return (
    <>
      <QuoteTextWrapper>
        <button
          className="paginateBtn prevBtn"
          type="button"
          aria-label={`Previous quote: Number ${checkNumber(index - 1)} of ${
            quotes.length
          }`}
          title={title}
          onClick={prevQuote}
        >
          <FaChevronLeft />
        </button>

        <QuoteBlock>
          <QuoteText>{body.body}</QuoteText>

          <h4>
            {title}{" "}
            <span
              style={{
                color: "var(--grey-7)",
                fontSize: "1.2rem",
                fontWeight: "lighter",
                textTransform: "uppercase",
              }}
            >
              | {subCategory.title}
            </span>
          </h4>
          <cite>{author.name} - #Ashalleyistics</cite>
        </QuoteBlock>

        <button
          className="paginateBtn nextBtn"
          type="button"
          aria-label={`Next quote: Number ${checkNumber(index + 1)} of ${
            quotes.length
          }`}
          title={title}
          onClick={nextQuote}
        >
          <FaChevronRight />
        </button>
      </QuoteTextWrapper>
    </>
  )
}

export default QuoteScroller
