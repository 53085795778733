import React from "react"
import { Link } from "gatsby"

import { EditorialItem } from "./EditorialElements"

const EditorialCard = ({ node }) => {
  return (
    <EditorialItem>
      <Link
        to={`/${node.category.slug}/${node.subCategory.slug}/${node.slug}/`}
      >
        <time>{node.date}</time>
        <p>{node.title}</p>
      </Link>
    </EditorialItem>
  )
}

export default EditorialCard
