import styled from "@emotion/styled"
import { Link } from "gatsby"

export const ExecSection = styled.section`
  grid-column: full-start / full-end;

  display: flex;
  flex-wrap: wrap;
  max-width: 192rem;
  margin: var(--margin-center);
`

export const ExecHero = styled.figure`
  color: rgba(250, 250, 250, 0.85);
  flex: 1 1 calc(65% - 5vw);
  transition: all 0.3s ease-in-out;

  display: grid;

  .execImage {
    grid-area: 1 / 1;
    /* min-height: 65vh; */
    /* min-height: 55vh; */
    /* min-height: 62rem; */
    min-height: 39vh;
    transition: all 0.3s ease-in-out;
  }

  &:hover h1,
  &:focus h1 {
    opacity: 0.75;
    color: var(--primaryYellow);
  }
  &:hover img,
  &:focus img {
    transform: scale(1.1);
  }
`

export const ExecCaption = styled.figcaption`
  background: linear-gradient(
    to bottom,
    rgba(9, 9, 9, 0),
    rgba(0, 0, 0, 0.73) 73%,
    rgba(39, 39, 39, 1) 100%
  );
  grid-area: 1 / 1;
  padding: 0 calc((100% - 86.3445378%) / 2) 4rem;
  position: relative;

  display: grid;
  align-content: end;

  p {
    color: var(--mediumGreyTwo);
    font-size: 1.2rem;
    opacity: 0.55;
  }

  span {
    color: var(--primaryYellow);
    text-transform: uppercase;
    font-weight: 700;
  }

  h1 {
    font-variation-settings: "wght" 900, "ital" 1;
    line-height: 1.0539;
    margin: 0;
    transition: all 0.3s ease-in-out;

    display: flex;

    a::after {
      content: "";
      background-color: var(--primaryYellow);
      display: inline-block;
      height: 0.4625em;
      width: 0.4em;
      right: -0.2em;
      opacity: 0.65;
      position: relative;
    }
  }

  @media (max-width: 52.5em) {
    padding-bottom: 2rem;
    h1 {
//       font-size: 2.609rem;
//       line-height: 1.2;
     font-size: 2.909rem;
    line-height: 1.3;
    }
  }
`

// Listing articles
export const ExecListStyles = styled.div`
  flex: 1 0 35%;
  padding: 2rem 1vw 0 5vw;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(28rem, 1fr));
  grid-gap: 3rem;
  align-items: end;

  @media (max-width: 52.5em) {
    padding: 4rem 5vw 0 5vw;
    grid-gap: 3rem 4rem;
  }
`

export const ExecCard = styled.article`
  border-bottom: 1px solid var(--grey-3);
  transition: all 0.3s ease-in;

  &:hover {
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
    opacity: 0.75;
  }
`

export const ExecCardLink = styled(Link)`
  display: flex;

  .articleImage {
    flex: 1;
    width: 12.2rem;
    /* height: 11.7rem; */
    height: 8rem;
    margin-left: 3.4rem;
    align-self: flex-end;
  }
`

export const ArticleContent = styled.div`
  flex: 3;

  h3 {
    font-size: 1.4rem;
    line-height: 1.25;
    margin-bottom: 1.5rem;
  }

  .excerpt {
    color: #1d1c1c;
    color: currentColor;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    /* @media (max-width: 52.5em) {
      display: none;
    } */
  }
`
