import React from "react"
import { StyledHeadingBar } from "../../styles/headings"

import { StoriesSection, StoriesWrapper } from "./StoriesElements"
import ImageCard from "../../utils/cards/ImageCard"
// import StoryCard from "./StoryCard"

const RecentStory = ({ storyNodes }) => {
  return (
    <StoriesSection>
      <StyledHeadingBar style={{ gridColumn: "2/-2" }}>
        Latest Stories <span>Recommended</span>
      </StyledHeadingBar>
      <StoriesWrapper>
        {/* {storyNodes.map(node => {
          return (
            <StoryCard
              key={node.id}
              story={node}
              fullSlug={`/${node.category.slug}/${node.subCategory.slug}/${node.slug}`}
            />
          )
        })} */}
        {storyNodes.map(node => {
          return (
            <ImageCard
              key={node.id}
              story={node}
              fullSlug={`/${node.category.slug}/${node.subCategory.slug}/${node.slug}`}
            />
          )
        })}
      </StoriesWrapper>
    </StoriesSection>
  )
}

export default RecentStory
