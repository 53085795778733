import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {
  ExecSection,
  ExecHero,
  ExecCaption,
  ExecListStyles,
} from "./ExecutiveElements"
import ExecutiveCard from "./ExecutiveCard"

const BusinessExecutive = ({
  title,
  slug,
  pubDate,
  validDate,
  subCategory,
  image,
  articles,
}) => {
  const imageData = getImage(image)
  return (
    <ExecSection>
      <ExecHero>
        <GatsbyImage
          className="execImage"
          image={imageData}
          alt={ (image?.description && image?.description) || title}
          imgStyle={{ transition: "all 0.3s ease-in-out" }}
        />
        <ExecCaption>
          <p>
            <time dateTime={validDate}>{pubDate}</time> |{" "}
            <span>{subCategory}</span>
          </p>
          <h1>
            <Link to={slug}>{title}</Link>
          </h1>
        </ExecCaption>
      </ExecHero>

      <ExecListStyles>
        {articles.map(node => {
          return <ExecutiveCard key={node.id} article={node} />
        })}
      </ExecListStyles>
    </ExecSection>
  )
}

export default BusinessExecutive
